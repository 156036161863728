export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDYv02aLpqUgtJIP9CbT31mOHpu0a77xLM",
    authDomain: "marcelo.intaschi.com",
    databaseURL: "https://marcelointaschi.firebaseio.com",
    projectId: "marcelointaschi",
    storageBucket: "marcelointaschi.appspot.com",
    messagingSenderId: "542053337228",
    appId: "1:442235031109:prd:331c3f03d8dbb330t58e22",
    measurementId: "M-BRGCB65FNN"
  }
};
